export interface RawCity {
  _id: string;
  city: string;
  postal_code: string;
  state: string;
}

export interface City {
  slug: string;
  name: string;
  postalCode: string;
  state: string;
  radius?: undefined;
}

export interface RawState {
  _id: string;
  name: string;
  code: string;
  radius: string; // in miles
  postal_code: string;
}

export interface State {
  code: string;
  name: string;
  radius: string;
  postalCode: string;
}

export const isCity = (location: City | State): location is City => {
  return 'slug' in location;
};
