import Checkbox, { CheckboxProps } from '@/components/atoms/legacy/Checkbox';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React, { PropsWithChildren, type JSX } from 'react';

export interface FormCheckboxProps extends CheckboxProps {
  label?: string;
  checkboxLabel: string | JSX.Element;
  invalid?: boolean;
  errorMessage?: string;
  checkBoxStyle?: string;
}

const FormCheckbox: React.FC<PropsWithChildren<FormCheckboxProps>> = ({
  className = '',
  checkBoxStyle = '',
  label,
  checkboxLabel,
  invalid,
  errorMessage,
  onChange,
  children,
  ...props
}) => {
  return (
    <label
      className={`${className} cursor-pointer text-body2Regular text-neutral-800`}
    >
      <section className="flex items-center space-x-[6px] text-body1Light text-neutral-900">
        <Checkbox
          className={`${invalid ? 'error' : ''} ${checkBoxStyle}`}
          onChange={onChange}
          {...props}
        />
        <span>{checkboxLabel}</span>
        {children}
      </section>
      {errorMessage && (
        <span
          className={`error-message ${
            invalid ? 'flex' : ''
          } mt-[4px] items-center text-red-dark`}
        >
          <ExclamationCircleIcon className="mr-xs size-l fill-red-light" />
          {errorMessage}
        </span>
      )}
    </label>
  );
};

export default FormCheckbox;
