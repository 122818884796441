import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import FormSelect from '@/components/molecules/FormSelect';
import { ListGridViewToggle } from '@/components/molecules/ListGridViewToggle';
import { SearchBarWithMenu } from '@/components/molecules/SearchBarWithMenu/SearchBarWithMenu';
import { useSaveRecentSearchMutation } from '@/hooks/queries/recent-searches';
import useWindowScrolledFromTop from '@/hooks/useWindowScrolled';
import { SEARCH_CONSTANTS, SORT_BY_OPTIONS } from '@/lib/constants';
import { FetchProductsResult } from '@/lib/schema/inventory/types';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { FilterType, UpdateFilterActionFields } from '@/types/filters';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useRef, useState } from 'react';

interface InventoryPageHeaderProps {
  autoCompleteResults: {
    searchMakes: string[];
    searchModels: Record<string, string[]>;
  };
  updateFilter: (body: UpdateFilterActionFields) => void;
  h1Tag: string;
  setIsGridView: (isGridView: boolean) => void;
  isGridView: boolean;
  onSortChange: (sort: string) => void;
  sort: string;
  data?: FetchProductsResult | null;
  filters: FilterType;
  isML: boolean;
  isDesktop: boolean;
  setPanelOpen: (isOpen: boolean) => void;
  filterCount: number;
  updatePath: (params: { sortOverride: string }) => void;
  setSort: (sort: string) => void;
}

export const InventoryPageHeader: React.FC<InventoryPageHeaderProps> = ({
  autoCompleteResults,
  updateFilter,
  h1Tag: initialH1Tag,
  setIsGridView,
  isGridView = true,
  onSortChange,
  data,
  sort,
  filters,
  isML,
  isDesktop,
  setPanelOpen,
  filterCount,
  updatePath,
  setSort,
}) => {
  const [searchInput, setSearchInput] = useState<string>(filters.vector_search);
  const [showOptions, setShowOptions] = useState(false);
  const [pageHeaderStyle, setPageHeaderStyle] = useState('');
  const scrolledFromTop = useWindowScrolledFromTop();
  const { mutateAsync: saveRecentSearch } = useSaveRecentSearchMutation();

  const initialFilters = useRef(filters);

  const h1Tag = useMemo(() => {
    const filtersChanged =
      JSON.stringify(filters) !== JSON.stringify(initialFilters.current);
    const isDefaultHeader = initialH1Tag === SEOMetaTags.searchPage.h1Tag;
    return filtersChanged && !isDefaultHeader
      ? SEOMetaTags.searchPage.h1Tag
      : initialH1Tag;
  }, [initialH1Tag, filters]);

  useEffect(() => {
    if (scrolledFromTop) {
      setPageHeaderStyle('ml:fadeOut');
      setTimeout(() => setPageHeaderStyle('ml:hidden'), 300);
    } else {
      setPageHeaderStyle('ml:fadeIn');
    }
  }, [scrolledFromTop]);

  return (
    <section className="relative flex w-full flex-col gap-s">
      <div className={`hidden ml:flex ${pageHeaderStyle}`}>
        <h1 className="text-h4Regular text-neutral-900 l:text-h3Regular">
          {h1Tag}
        </h1>
      </div>

      <section className="flex">
        <section className="flex basis-full items-center gap-xl">
          {(isML || isDesktop) && (
            <div className="inline-flex h-[48px] w-full">
              <SearchBarWithMenu
                input={searchInput}
                setInput={setSearchInput}
                models={autoCompleteResults.searchModels}
                makes={autoCompleteResults.searchMakes}
                handleVectorSearch={async (input) => {
                  if (input) {
                    await saveRecentSearch(input);
                    updateFilter({
                      fields: {
                        vector_search: input,
                        sort: SEARCH_CONSTANTS.initialSort,
                      },
                    });
                    if (sort !== SEARCH_CONSTANTS.initialSort) {
                      setSort(SEARCH_CONSTANTS.initialSort);
                      updatePath({
                        sortOverride: SEARCH_CONSTANTS.initialSort,
                      });
                    }
                    setSearchInput(input);
                  }
                }}
                setShowOptions={setShowOptions}
                showOptions={showOptions}
                currentVectorSearch={filters.vector_search}
              />
            </div>
          )}
          <div className="flex w-full flex-col gap-l ml:hidden">
            <div className="flex w-full items-center justify-between gap-l">
              <div
                className="flex items-center gap-s"
                onClick={() => setPanelOpen(true)}
              >
                <AdjustmentsHorizontalIcon className="h-xl w-xl" />
                <span className="whitespace-nowrap text-body1Regular">
                  Filters
                </span>

                {filterCount > 0 && (
                  <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
                    {filterCount}
                  </div>
                )}
              </div>

              <div className={`flex items-center justify-center gap-s py-m`}>
                <EVSavingsToggle label="EV Savings" />
              </div>
            </div>
          </div>
          <div className="hidden w-full items-center justify-end gap-xl ml:flex">
            <div className="hidden items-center justify-end m:flex">
              <EVSavingsToggle label="EV Savings" />
            </div>
            <div className="hidden items-center gap-s l:flex">
              <div className="text-nuetral-900 text-body1Light">View</div>
              <ListGridViewToggle
                gridViewEnabled={isGridView}
                setIsGridView={setIsGridView}
              />
            </div>
            <FormSelect
              className="h-fit-content basis-1/3 ml:block"
              variant={DropdownMenuItemVariants.SingleSelect}
              disabled={data?.total === 0 || filters.vector_search !== ''}
              value={[sort]}
              onChange={([value]) => onSortChange(value)}
              flexDirection={'col'}
              options={SORT_BY_OPTIONS}
            />
          </div>
        </section>
      </section>
    </section>
  );
};
