import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Link from '@/components/atoms/legacy/Link';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import { CarTypes } from '@/components/molecules/GVECalculator/GVECalculator';
import { InfoDisplay } from '@/components/molecules/InfoDisplay';
import Logo from '@/components/molecules/Logo';
import { VLPCardImageContainer } from '@/components/molecules/VLPCardImageContainer/VLPCardImageContainer';
import { useSavedVehicles } from '@/context/SavedVehicles';
import {
  DEFAULT_COSTS,
  DEFAULT_US_ZIP_CODE,
  GAS_SAVINGS_INITIAL_TERM,
  GOOGLE_TRACK_INFO,
  PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION,
} from '@/lib/constants';
import { getFuelCosts } from '@/lib/fuelCost/getFuelCosts';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import {
  findPurchaseFederalIncentive,
  formatIncentivesFromAPI,
  getTotalIncentiveAmount,
  getTotalRebateAmount,
} from '@/lib/incentiveUtils';
import {
  IncentiveResponse,
  JDPowerIncentive,
} from '@/lib/schema/incentives/types';
import { VehicleBadgeIds } from '@/lib/schema/inventory/types';
import { ValueWithUnit } from '@/types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { FC, useMemo } from 'react';
import { VehiclePrice } from '../VehiclePrice/VehiclePrice';

export interface CustomOrderCardProps {
  id: string;
  make: string;
  model: string;
  year: string;
  image: string;
  msrp: number;
  range: ValueWithUnit;
  incentives: IncentiveResponse | null;
  bodyType: string | null;
  warranty: string | null;
  fuelType: string | null;
  batteryCapacity: ValueWithUnit | null;
}
interface CustomOrderCardComponentProps extends CustomOrderCardProps {
  isSaved: boolean;
  isMobile: boolean;
  isGridView: boolean;
  isListedPrice: boolean;
  location: string;
}

export const CustomOrderListingCard: FC<CustomOrderCardComponentProps> = (
  props
) => {
  const {
    id,
    make,
    model,
    year,
    incentives,
    bodyType,
    image,
    msrp,
    isSaved = false,
    location,
    isListedPrice,
    isMobile,
    isGridView,
    warranty,
    fuelType,
    range,
    batteryCapacity,
  } = props;

  const router = useRouter();

  const analyticsEvent = {
    ...GOOGLE_TRACK_INFO.customOrderHomePageButton,
    pageTarget: `/build/${location}/${make}/${year}/${model}`,
  } as AnalyticsEvent;

  const href = {
    pathname: `/build/${location || DEFAULT_US_ZIP_CODE}/${make}/${year}/${model}`,
    query: {},
  };

  const {
    saved,
    saveVehicle,
    loading: savingVehicle,
  } = useSavedVehicles({
    storeField: 'build',
    initiallySaved: isSaved,
    infoToSave: {
      id,
      price: `$${String(msrp).toLocaleString()}`,
      year: Number(year),
      make,
      model,
      image,
    },
  });

  const handleSaveVehicle = async (
    e?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    if (e) {
      e.stopPropagation();
    }
    await saveVehicle(id);
  };

  const { savingsPerYear: estimatedFuelSavingsPerYear } = getFuelCosts({
    carType: bodyType || CarTypes.Sedan,
    electricityCost: Number(DEFAULT_COSTS.electricityCost),
    gasolineCost: Number(DEFAULT_COSTS.gasolineCost),
    mileagePerYear: Number(DEFAULT_COSTS.mileagePerYear),
    years: Number(GAS_SAVINGS_INITIAL_TERM),
    fuelType: fuelType === 'Hybrid' ? 'Hybrid' : 'Electric',
  });

  const {
    state: stateIncentives,
    local: localIncentives,
    federal: federalIncentives,
  } = formatIncentivesFromAPI(incentives);

  const monetaryIncentiveAmount = getTotalIncentiveAmount([
    ...(stateIncentives || []),
    ...(localIncentives || []),
    ...(federalIncentives || []),
  ]);

  const rebateAmount = getTotalRebateAmount([
    ...(stateIncentives || []),
    ...(localIncentives || []),
    ...(federalIncentives || []),
  ]);

  const hasIncentives =
    stateIncentives.some(
      (incentive: JDPowerIncentive) => !!incentive.max_amount
    ) ||
    localIncentives.some(
      (incentive: JDPowerIncentive) => !!incentive.max_amount
    ) ||
    federalIncentives.some((incentive: JDPowerIncentive) =>
      findPurchaseFederalIncentive(incentive)
    );

  const infoBlocks: { text: string; postFix: string }[] = useMemo(() => {
    const result: { text: string; postFix: string }[] = [];
    if (batteryCapacity && batteryCapacity.value) {
      result.push({
        text: `${batteryCapacity.value} ${batteryCapacity.unit}`,
        postFix: 'Battery Capacity',
      });
    }
    if (warranty) {
      result.push({
        text: warranty.split('/').join(' / '),
        postFix: `Manufacturer's Warranty`,
      });
    }

    if (range && Number(range.value) > 0) {
      result.push({
        text: `${range.value} ${range.unit}`,
        postFix: 'Est. Range',
      });
    }

    return result;
  }, [batteryCapacity, range, warranty]);

  return (
    <Link
      aria-label={`link to build ${year} ${make} ${model}`}
      analyticsEvent={analyticsEvent}
      href={href}
    >
      <div
        className={clsx(
          'flex cursor-pointer rounded-small border border-neutral-200 shadow-md transition-colors duration-500 hover:border-brandElectric-hover hover:shadow-lg',
          {
            'h-full flex-col': isGridView,
            'flex-row': !isGridView,
          }
        )}
      >
        <section
          className={clsx(
            'flex basis-2/5 overflow-hidden',
            { 'rounded-t-small': isGridView },
            { 'rounded-l-small': !isGridView }
          )}
        >
          <VLPCardImageContainer
            make={make}
            model={model}
            year={year}
            images={[image]}
            isMobile={isMobile}
            isColumnLayout={isGridView}
            saved={saved}
            handleSaveVehicle={handleSaveVehicle}
            savingVehicle={savingVehicle}
          />
        </section>

        <section
          className={clsx('group flex w-full flex-grow flex-col gap-l pt-xl', {
            'flex-1': !isGridView,
          })}
        >
          <section className="flex flex-col gap-l px-xl">
            <section className="flex flex-wrap gap-s">
              <Badge
                className="bg-neutral-200 text-neutral-800"
                label={'New'}
              />
              {fuelType === 'Hybrid' && (
                <Badge
                  className="!text-blue-dark"
                  label={PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION}
                  variant={BadgeVariants.Blue}
                />
              )}

              {hasIncentives && (
                <Badge
                  variant={BadgeVariants.Blue}
                  label={VehicleBadgeIds['ELIGIBLE FOR FEDERAL INCENTIVES']}
                />
              )}
            </section>
            <section className="flex flex-col gap-s">
              <div className="text-ellipsis text-body1Regular text-neutral-900 group-hover:text-blue-medium group-hover:underline">
                {year} {make} {model}
              </div>
              <div
                className={clsx('', {
                  'flex h-full': !isGridView,
                  hidden: isGridView,
                })}
              >
                {infoBlocks.map(({ text, postFix }, i) => (
                  <InfoDisplay
                    key={i}
                    className={`px-4 ${
                      i !== infoBlocks.length - 1
                        ? 'border-r border-[#E5E7EB]'
                        : ''
                    } ${i === 0 ? 'md:px-0 md:pr-4' : ''}`}
                    textContainerClassName="flex-col items-start justify-center"
                    main={{
                      text,
                      postFix,
                      className: 'text-[16px] font-normal',
                      postFixClassName: 'text-micro1 font-normal !pl-0',
                    }}
                  />
                ))}
              </div>
            </section>
          </section>

          <section className="flex h-full flex-col justify-end">
            <section
              className={clsx('flex gap-l px-xl', {
                'flex-col': isGridView,
              })}
            >
              <section className="flex flex-col justify-center">
                <VehiclePrice
                  isListedPrice={isListedPrice}
                  price={msrp}
                  appliedIncentiveTotal={monetaryIncentiveAmount + rebateAmount}
                  totalEstFuelSavingsPerYear={estimatedFuelSavingsPerYear}
                  gasTerm={Number(GAS_SAVINGS_INITIAL_TERM)}
                  variant={'VehicleCard'}
                />
              </section>
              <section className="flex flex-grow items-start">
                <div className="w-full pt-s">
                  <Button
                    size="small"
                    aria-label="View build"
                    analyticsEvent={analyticsEvent}
                    onClick={() => {
                      router.push(href);
                    }}
                    variant={ButtonVariants.Tertiary}
                  >
                    Build & Price
                  </Button>
                </div>
              </section>
            </section>
          </section>

          <section className="flex flex-grow items-end">
            <div className="flex w-full items-center justify-between gap-xl bg-neutral-200 px-xl py-l">
              <Logo className={`relative flex-none`} width={59} height={12} />
              <div className="flex whitespace-nowrap text-microMedium text-brandMidnight">
                Custom order this model
              </div>
            </div>
          </section>
        </section>
      </div>
    </Link>
  );
};
