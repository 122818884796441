import TextButton from '@/components/atoms/legacy/TextButton';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import { SORT_BY_OPTIONS } from '@/lib/constants';
import { FilterChip, FilterType } from '@/types/filters';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { FetchProductsResult } from '../../../lib/schema/inventory/types';
import { SearchFilterGeneralProps } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

export interface SearchFilterChipsSectionProps
  extends SearchFilterGeneralProps {
  clearFilters: () => void;
  onSortChange: (sort: string) => void;
  sort: string;
  data?: FetchProductsResult | null;
  filterChips: FilterChip[];
  handleUpdateFilter: (
    filterName: keyof FilterType,
    filterItem: string
  ) => void;
}

export const SearchFilterChipsSection: React.FC<
  SearchFilterChipsSectionProps
> = ({
  filters,
  clearFilters,
  onSortChange = () => {},
  sort = '',
  data,
  filterChips,
  handleUpdateFilter,
}) => {
  const [openFilterChips, setOpenFilterChips] = useState<boolean>(true);
  const filterChipsRef = useRef<HTMLDivElement>(null);
  const isSearchConflicted = data?.size === 0 && !!filters.vector_search;
  const shouldShowFilterChips = openFilterChips && filterChips.length > 0;

  return (
    <section>
      <section className="mb-[10px] hidden justify-between ml:mb-s ml:flex">
        <div className="flex items-center gap-s">
          <h4 className="text-h3SemiBold m:text-h4Regular">Filters</h4>
          {filterChips.length > 0 && !openFilterChips && (
            <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
              {filterChips.length}
            </div>
          )}
        </div>
        <div className="flex items-center gap-s">
          <TextButton
            className="px-s text-body2Regular text-blue-medium"
            onClick={() => {
              clearFilters();
              setOpenFilterChips(false);
            }}
          >
            Reset all
          </TextButton>
          {filterChips.length > 0 && (
            <>
              <div className="h-full w-px bg-neutral-200" />
              <button onClick={() => setOpenFilterChips(!openFilterChips)}>
                {openFilterChips ? (
                  <ChevronUpIcon className="size-xl" />
                ) : (
                  <ChevronDownIcon className="size-xl" />
                )}
              </button>
            </>
          )}
        </div>
      </section>
      <section className="hidden flex-col-reverse gap-l ml:flex ml:flex-col">
        {shouldShowFilterChips && (
          <div
            className="flex flex-wrap gap-s overflow-hidden transition-all duration-300"
            ref={filterChipsRef}
          >
            {filterChips
              .filter(
                (_, index) =>
                  !isSearchConflicted || index !== filterChips.length - 1
              )
              .map((chip, index) => {
                return (
                  <Badge
                    key={`${chip.label}_${index}`}
                    onClick={chip.onClose}
                    variant={chip.variant}
                    label={chip.label}
                    iconPosition="right"
                    secondaryIcon={
                      chip.filterName === 'postal' ? (
                        <MapPinIcon className="h-l w-l" />
                      ) : undefined
                    }
                    icon={
                      <XMarkIcon width={20} height={20} strokeWidth={1.5} />
                    }
                  />
                );
              })}
          </div>
        )}
        {isSearchConflicted && filterChips?.length > 0 && (
          <div
            className={`flex flex-col-reverse gap-s ${openFilterChips ? '' : 'pb-l'} ml:flex-col`}
          >
            <div className="flex items-center justify-start gap-xs text-microMedium text-red-medium">
              <InformationCircleIcon className="h-l w-l" />
              <div>Conflicting filters removed from search.</div>
            </div>
            <div className="ease flex flex-wrap gap-s transition-all duration-300">
              <Badge
                key={filterChips[filterChips.length - 1]?.label}
                onClick={() => {
                  const lastChip = filterChips[filterChips.length - 1];
                  handleUpdateFilter(
                    lastChip.filterName,
                    filters[lastChip.filterName] as string
                  );
                }}
                variant={BadgeVariants.Red}
                label={filterChips[filterChips.length - 1]?.label}
                iconPosition="right"
                icon={<XMarkIcon width={20} height={20} strokeWidth={1.5} />}
              />
            </div>
          </div>
        )}
      </section>
      <section className="ml:hidden">
        <div className="mb-l text-h3Regular text-neutral-900">Sort by</div>
        <FormSelect
          className="[&>label>button>svg>path]:stroke-blueGrey-0·[&>label>button]:text-blueGrey-0·block·min-w-[20%]·flex-1·[&>label>button]:text-bodyBold4"
          variant={DropdownMenuItemVariants.SingleSelect}
          disabled={data?.total === 0 || filters.vector_search !== ''}
          value={[sort]}
          onChange={([value]) => onSortChange(value)}
          flexDirection={'col'}
          options={SORT_BY_OPTIONS}
        />
        <hr className="mt-l border-[1px] border-neutral-200" />
      </section>

      {filterChips?.length > 0 && (
        <hr
          className={`${
            openFilterChips ? 'mt-l' : ''
          } hidden border-[1px] border-neutral-200 ml:block`}
        />
      )}
    </section>
  );
};
