import Link from 'next/link';
import React from 'react';
import { POPULAR_MAKES_EXTENDED } from '../../../lib/constants';
import { City, isCity, State } from '../../../lib/schema/geo/types';
import { SeoLocation } from '../../../lib/staticQueries';
import { slugify } from '../../../lib/utils';
import { FilterType } from '../../../types/filters';
import { ResponsiveWrapper } from '../../atoms/ResponsiveWrapper/ResponsiveWrapper';
import Accordion from '../../molecules/Accordion';

const locationSlug = (location: City | State) => {
  return isCity(location) ? location.slug : location.code;
};

interface RelatedLinkSectionProps {
  title: string;
  links: {
    href: string;
    label: string;
  }[];
}

const RelatedLinkSection: React.FC<RelatedLinkSectionProps> = ({
  title,
  links,
}) => {
  return (
    <div className="border-b border-neutral-200 pb-l last:border-b-0">
      <Accordion
        buttonClassName="text-neutral-900 text-h4Regular"
        icon={{ id: 'chevron' }}
        title={title}
      >
        <div className="columns-3 space-y-m py-s">
          {links.map(({ href, label }) => {
            return (
              <div
                key={label}
                className="text-body2Medium text-text-link-action"
              >
                <Link href={slugify(href)}>{label}</Link>
              </div>
            );
          })}
        </div>
      </Accordion>
    </div>
  );
};

interface RelatedLinksProps {
  filters: FilterType;
  modelsByMake: Record<string, string[]>;
  seoLocation: SeoLocation | null;
  topCities: City[];
  citiesInSameState: City[];
}

export const RelatedLinks: React.FC<RelatedLinksProps> = ({
  filters,
  modelsByMake,
  seoLocation,
  topCities,
  citiesInSameState,
}) => {
  const filteredMake = filters.make.length === 1 ? filters.make[0] : null;
  const filteredModel = filters.model.length === 1 ? filters.model[0] : null;

  return (
    <ResponsiveWrapper>
      <div className="mx-auto flex max-w-[980px] flex-col gap-xl py-[80px]">
        {/* if make, we show “same make by popular cities” and “same make by different models” */}
        {filteredMake ? (
          <>
            <RelatedLinkSection
              title={`${filteredMake} by cities`}
              links={topCities.map((city) => ({
                href: `/${filteredMake}/${city.slug}`,
                label: `${filteredMake} in ${city.name}, ${city.state.toUpperCase()}`,
              }))}
            />
            <RelatedLinkSection
              title={`${filteredMake} by models`}
              links={(modelsByMake?.[filteredMake] ?? []).map((model) => ({
                href: `/${filteredMake}/${model}`,
                label: `${filteredMake} ${model}`,
              }))}
            />
          </>
        ) : null}

        {/* if make and model, we show “same make same model by popular cities” */}
        {filteredMake && filteredModel ? (
          <RelatedLinkSection
            title={`${filteredMake} ${filteredModel} by cities`}
            links={topCities.map((city) => ({
              href: `/${filteredMake}/${filteredModel}/${city.slug}`,
              label: `${filteredMake} ${filteredModel} in ${city.name}, ${city.state.toUpperCase()}`,
            }))}
          />
        ) : null}

        {/* if make and location, we show “models in the same location */}
        {filteredMake && seoLocation ? (
          <RelatedLinkSection
            title={`${filteredMake} by models in ${seoLocation.title}`}
            links={(modelsByMake[filteredMake] ?? []).map((model) => ({
              href: `/${filteredMake}/${model}/${locationSlug(seoLocation.data)}`,
              label: `${filteredMake} ${model} in ${seoLocation.title}`,
            }))}
          />
        ) : null}

        {/* if location, we show “popular makes in the same location" and “evs in different cities in same state” */}
        {seoLocation ? (
          <>
            <RelatedLinkSection
              title={`Popular makes in ${seoLocation.title}`}
              links={POPULAR_MAKES_EXTENDED.map((make) => ({
                href: `/${make}/${locationSlug(seoLocation.data)}`,
                label: `${make} in ${seoLocation.title}`,
              }))}
            />
            <RelatedLinkSection
              title={`Find EV in ${isCity(seoLocation.data) ? seoLocation.data.state.toUpperCase() : seoLocation.title}`}
              links={citiesInSameState?.map((city) => ({
                href: `/${city.slug}`,
                label: `${city.name}, ${city.state.toUpperCase()}`,
              }))}
            />
          </>
        ) : null}

        {/* if no make, no model, no location, we show “popular makes” and “popular cities” */}
        {!filteredMake && !filteredModel && !seoLocation ? (
          <>
            <RelatedLinkSection
              title={`Popular makes`}
              links={POPULAR_MAKES_EXTENDED.map((make) => ({
                href: `/${make}`,
                label: make,
              }))}
            />
            <RelatedLinkSection
              title={`Vehicles in popular cities`}
              links={topCities.map((city) => ({
                href: `/${city.slug}`,
                label: `${city.name}, ${city.state.toUpperCase()}`,
              }))}
            />
          </>
        ) : null}
      </div>
    </ResponsiveWrapper>
  );
};
