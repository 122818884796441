import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type Breakpoint =
  | {
      marginLeft?: boolean;
      marginRight?: boolean;
    }
  | { fullWidth?: boolean };
interface ResponsiveWrapperProps extends PropsWithChildren {
  xs?: Breakpoint;
  s?: Breakpoint;
  m?: Breakpoint;
  ml?: Breakpoint;
  l?: Breakpoint;
}

/**
 * A wrapper component that provides responsive styling.
 *
 *
 * @param {React.ReactNode} props.children - The content inside the wrapper.
 * @param {Breakpoint} [props.xs={ marginLeft: true, marginRight: true }] - The breakpoint for extra-small screens (mobile).
 * @param {Breakpoint} [props.s={ marginLeft: true, marginRight: true }] - The breakpoint for small screens (tablet).
 * @param {Breakpoint} [props.m={ marginLeft: true, marginRight: true }] - The breakpoint for medium screens (large tablet/small desktop).
 * @param {Breakpoint} [props.ml={ marginLeft: true, marginRight: true }] - The breakpoint for medium-large screens (desktop).
 * @param {Breakpoint} [props.l={ marginLeft: true, marginRight: true }] - The breakpoint for large screens (large desktop).
 *
 * @returns {JSX.Element} The rendered wrapper component.
 *
 * design: https://www.figma.com/design/NOuZ1tp0iBnn3EhOtsS8wb/Marketplace-Improvements%3A-Q2-2024?node-id=4516-123315&t=bKfr7cgZ1jHyGLTF-1
 *
 */
export const ResponsiveWrapper: FC<ResponsiveWrapperProps> = ({
  children,
  xs = { marginLeft: true, marginRight: true },
  s = { marginLeft: true, marginRight: true },
  m = { marginLeft: true, marginRight: true },
  ml = { marginLeft: true, marginRight: true },
  l = { marginLeft: true, marginRight: true },
}) => {
  const showRightMargin = (breakpoint: Breakpoint) => {
    if ('fullWidth' in breakpoint) {
      return !breakpoint.fullWidth;
    }
    if ('marginRight' in breakpoint) {
      return breakpoint.marginRight;
    }
    return true;
  };

  const showLeftMargin = (breakpoint: Breakpoint) => {
    if ('fullWidth' in breakpoint) {
      return !breakpoint.fullWidth;
    }
    if ('marginLeft' in breakpoint) {
      return breakpoint.marginLeft;
    }
    return true;
  };

  return (
    <div
      className={clsx('w-full', {
        // mobile (xs)
        'pr-l': showRightMargin(xs),
        'pl-l': showLeftMargin(xs),
        'pr-0': !showRightMargin(xs),
        'pl-0': !showLeftMargin(xs),

        // tablet (s)
        's:pr-xl': showRightMargin(s),
        's:pl-xl': showLeftMargin(s),
        's:pr-0': !showRightMargin(s),
        's:pl-0': !showLeftMargin(s),

        // tablet/desktop (m)
        'm:pr-xl': showRightMargin(m),
        'm:pl-xl': showLeftMargin(m),
        'm:pr-0': !showRightMargin(m),
        'm:pl-0': !showLeftMargin(m),

        // desktop (ml)
        'ml:pr-3xl': showRightMargin(ml),
        'ml:pl-3xl': showLeftMargin(ml),
        'ml:pr-0': !showRightMargin(ml),
        'ml:pl-0': !showLeftMargin(ml),

        // xl (l)
        'l:pr-[max(64px,calc((100vw-1720px)/2))]': showRightMargin(l),
        'l:pl-[max(64px,calc((100vw-1720px)/2))]': showLeftMargin(l),
        'l:pr-0': !showRightMargin(l),
        'l:pl-0': !showLeftMargin(l),
      })}
    >
      {children}
    </div>
  );
};
