import {
  SeoEnrichmentContent as BaseSeoEnrichmentContent,
  VehicleListingType,
} from '@/lib/schema/inventory/types';
import { useMemo } from 'react';

interface SeoEnrichmentContentProps extends BaseSeoEnrichmentContent {
  totalCars: number;
  priceRange?: { min: number; max: number };
  popularModels?: { model: string; minPrice: number }[];
  listings: VehicleListingType[];
}

const processSEOData = (listings: VehicleListingType[]) => {
  if (!listings?.length) return null;

  const prices = listings.map((v) => v.price.value || 0);
  const modelCounts = listings.reduce(
    (acc, vehicle) => {
      const { model } = vehicle;
      if (model) acc[model] = (acc[model] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>
  );

  return {
    priceRange: {
      min: Math.min(...prices),
      max: Math.max(...prices),
    },
    popularModels: Object.entries(modelCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([model]) => ({
        model,
        minPrice: Math.min(
          ...listings
            .filter((v) => v.model === model)
            .map((v) => v.price.value || 0)
        ),
      })),
  };
};

const formatPrice = (price: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(price);

export const SeoEnrichmentContent = ({
  title,
  content,
  listings,
  make = '',
  model = '',
  year = '',
  location = '',
  totalCars,
}: SeoEnrichmentContentProps) => {
  const seoData = useMemo(() => {
    return processSEOData(listings);
  }, [listings]);

  const processedContent = useMemo(() => {
    const replacements = {
      totalCars: totalCars.toLocaleString(),
      priceRange: seoData?.priceRange
        ? `${formatPrice(seoData.priceRange.min)} to ${formatPrice(seoData.priceRange.max)}`
        : '',
      popularModelsList: seoData?.popularModels
        ? seoData.popularModels
            .slice(0, 3)
            .map(
              (m) =>
                `• ${m.model} - Starting from USD ${formatPrice(m.minPrice)}`
            )
            .join('\n')
        : '',
      make,
      model,
      year,
      location,
    };

    let initialContent = content;
    Object.entries(replacements)
      .filter(([, value]) => !!value)
      .forEach(([key, value]) => {
        initialContent = initialContent.replace(
          new RegExp(`{{${key}}}`, 'g'),
          value.toString()
        );
      });
    return initialContent;
  }, [content, location, make, model, seoData, year, totalCars]);

  return (
    <div className="flex flex-col gap-l">
      <p className="text-body1Medium text-neutral-800">{title}</p>
      <div
        className="text-microLight text-neutral-800"
        dangerouslySetInnerHTML={{
          __html: processedContent.replace(/\n/g, '<br/>'),
        }}
      />
    </div>
  );
};
